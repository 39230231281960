import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import RichText from '../RichText/RichText';

import * as styles from './LastFooter.module.scss';

import PatternTop from '../../images/Pattern-menu-top.svg';
import PatternBottom from '../../images/Pattern-menu-bottom.svg';

/**
 * This is the LastFooter component.
 *
 * @author Maximilian Øystå Lloyd <max@apt.no>
 *
 * @return {JSX}
 */

const LastFooter = (props) => {
  const { text, ctaSupportingText, cta } = props;

  return (
    <div className={styles.wrapper}>
      <PatternTop className={styles.patternTop} />
      <PatternBottom className={styles.patternBottom} />
      <div className={styles.content}>
        <RichText blocks={text.text} />
        <div className={styles.ctaContainer}>
          <div className={styles.ctaSupportingText}>{ctaSupportingText}</div>
          <Button href={cta.file.asset.url} target="_blank" smallText>
            {cta.title}
          </Button>
        </div>
      </div>
    </div>
  );
};

LastFooter.propTypes = {
  ctaSupportingText: PropTypes.string.isRequired,
  cta: PropTypes.object.isRequired
};

export default LastFooter;
