import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import touches from 'touches';
import classNames from 'classnames';

import useWindowSize from '../../hooks/useWindowSize';
import useInView from '../../hooks/useInView';
import window from '../../utils/window';
import device from '../../utils/device';

import * as styles from './HeroDrawText.module.scss';
import RichText from '../RichText/RichText';

const NEW_PATH_POINT_THRESHOLD = 10;
const SIDE_MARGIN = 30;
// prettier-ignore
const DEFAULT_PATH_1 = [{ x: 143, y: 296 }, { x: 148, y: 283 }, { x: 153, y: 273 }, { x: 162, y: 258 }, { x: 169, y: 245 }, { x: 174, y: 235 }, { x: 182, y: 224 }, { x: 193, y: 212 }, { x: 206, y: 198 }, { x: 213, y: 190 }, { x: 231, y: 171 }, { x: 242, y: 163 }, { x: 251, y: 158 }, { x: 262, y: 151 }, { x: 276, y: 143 }, { x: 288, y: 138 }, { x: 304, y: 133 }, { x: 316, y: 130 }, { x: 328, y: 128 }, { x: 342, y: 128 }, { x: 354, y: 128 }, { x: 367, y: 131 }, { x: 378, y: 133 }, { x: 392, y: 137 }, { x: 404, y: 140 }, { x: 416, y: 143 }, { x: 430, y: 146 }, { x: 442, y: 149 }, { x: 452, y: 151 }, { x: 462, y: 153 }, { x: 475, y: 154 }, { x: 486, y: 154 }, { x: 499, y: 152 }, { x: 510, y: 149 }, { x: 523, y: 146 }, { x: 539, y: 140 }, { x: 556, y: 135 }, { x: 566, y: 131 }, { x: 580, y: 126 }, { x: 592, y: 120 }, { x: 603, y: 115 }, { x: 616, y: 106 }, { x: 626, y: 102 }, { x: 642, y: 92 }, { x: 658, y: 81 }, { x: 684, y: 69 }, { x: 697, y: 65 }, { x: 707, y: 60 }, { x: 744, y: 58 }, { x: 766, y: 57 }, { x: 799, y: 56 }, { x: 847, y: 54 }, { x: 882, y: 52 }, { x: 908, y: 52 }, { x: 934, y: 53 }, { x: 972, y: 58 }, { x: 994, y: 61 }, { x: 1022, y: 66 }, { x: 1046, y: 70 }, { x: 1088, y: 77 }, { x: 1111, y: 81 }, { x: 1130, y: 86 }, { x: 1148, y: 93 }, { x: 1170, y: 100 }, { x: 1188, y: 107 }, { x: 1209, y: 117 }, { x: 1213, y: 128 }];

const MOBILE_PATH_SCALE = 0.44;
// prettier-ignore
const DEFAULT_PATH_MOBILE_1 = [{ x: 143, y: 296 }, { x: 148, y: 283 }, { x: 153, y: 273 }, { x: 162, y: 258 }, { x: 169, y: 245 }, { x: 174, y: 235 }, { x: 182, y: 224 }, { x: 193, y: 212 }, { x: 206, y: 198 }, { x: 213, y: 190 }, { x: 231, y: 171 }, { x: 242, y: 163 }, { x: 251, y: 158 }, { x: 262, y: 151 }, { x: 276, y: 143 }, { x: 288, y: 138 }, { x: 304, y: 133 }, { x: 316, y: 130 }, { x: 328, y: 128 }, { x: 342, y: 128 }, { x: 354, y: 128 }, { x: 367, y: 131 }, { x: 378, y: 133 }, { x: 392, y: 137 }, { x: 404, y: 140 }, { x: 416, y: 143 }, { x: 430, y: 146 }, { x: 442, y: 149 }, { x: 452, y: 151 }, { x: 462, y: 153 }, { x: 475, y: 154 }, { x: 486, y: 154 }, { x: 499, y: 152 }, { x: 510, y: 149 }, { x: 523, y: 146 }, { x: 539, y: 140 }, { x: 556, y: 135 }, { x: 566, y: 131 }, { x: 580, y: 126 }, { x: 592, y: 120 }, { x: 603, y: 115 }, { x: 616, y: 106 }, { x: 626, y: 102 }, { x: 642, y: 92 }, { x: 658, y: 81 }, { x: 684, y: 69 }, { x: 697, y: 65 }, { x: 707, y: 60 }, { x: 744, y: 58 }, { x: 766, y: 57 }, { x: 799, y: 56 }, { x: 847, y: 54 }, { x: 882, y: 52 }, { x: 908, y: 52 }, { x: 934, y: 53 }, { x: 972, y: 58 }, { x: 994, y: 61 }, { x: 1022, y: 66 }, { x: 1046, y: 70 }, { x: 1088, y: 77 }, { x: 1111, y: 81 }, { x: 1130, y: 86 }, { x: 1148, y: 93 }, { x: 1170, y: 100 }, { x: 1188, y: 107 }, { x: 1209, y: 117 }, { x: 1213, y: 128 }, ]
  .slice(0, 50)
  .map((point) => {
    let x = point.x * MOBILE_PATH_SCALE;
    let y = point.y * MOBILE_PATH_SCALE;
    x -= 40;
    y += 30;
    return { x, y };
  });

// prettier-ignore
const DEFAULT_PATH_MOBILE_2 = DEFAULT_PATH_MOBILE_1
  .slice()
  .map((point) => {
    // Flip DEFAULT_PATH_MOBILE_1 coords
    const x = window.innerWidth - point.x + 15;
    const y = 320 - point.y;
    return { x, y };
  });

const MOBILE_PATH_LIST = [DEFAULT_PATH_MOBILE_1, DEFAULT_PATH_MOBILE_2]; // , DEFAULT_PATH_MOBILE_2

const HeroDrawText = ({ block }) => {
  const container = useRef();
  const hasRenderedInitially = useRef(false);
  const touchHandler = useRef(null);
  const mobileHandler = useRef(null);
  const [paths, setPaths] = useState([]);
  const inView = useInView(container);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [dimensions, setDimensions] = useState({
    width: windowWidth,
    height: windowHeight,
  });

  useLayoutEffect(() => {
    if (!container.current || !windowWidth) return;

    const width = container.current.clientWidth;
    const height = container.current.clientHeight;
    const textPaths = new Array(block.animatedTexts.length).fill(0).map((_, i) => ({
      points: [],
      text: block.animatedTexts[i],
      hidden: true,
    }));
    let prevX;
    let prevY;
    let currentTextIndex = 0;

    const updatePaths = (x, y, opts = { force: false, ignoreDelta: false, index: 0 }) => {
      if (x < SIDE_MARGIN) {
        x = SIDE_MARGIN;
      } else if (x > width - SIDE_MARGIN) {
        x = width - SIDE_MARGIN;
      }
      if (y < SIDE_MARGIN) {
        y = SIDE_MARGIN;
      } else if (y > height - SIDE_MARGIN) {
        y = height - SIDE_MARGIN;
      }

      if (prevX === undefined) {
        prevX = x;
        prevY = y;
      }

      const dx = x - prevX;
      const dy = y - prevY;

      if (opts.force || opts.ignoreDelta || Math.hypot(dx, dy) > NEW_PATH_POINT_THRESHOLD) {
        prevX = x;
        prevY = y;

        for (let i = 0; i < textPaths.length; i++) {
          if (currentTextIndex !== i) continue;

          const textPath = textPaths[i];

          if (!opts.force) {
            if (textPath.points.length > textPath.text.length * (device.handheld ? 1 : 1.75)) {
              // Re-initialize the next textpath
              currentTextIndex = (currentTextIndex + 1) % textPaths.length;
              textPaths[currentTextIndex].points = [];
              textPaths[currentTextIndex].hidden = false;

              // Hide the current one
              textPath.hidden = true;
              if (device.handheld) {
                mobileHandler.current.currentAutomationIndex = 0;
              }
            }

            if (!device.handheld || mobileHandler.current?.lastPushedIndex !== opts.index) {
              textPath.points.push({ x, y });

              if (device.handheld) {
                mobileHandler.current.lastPushedIndex = opts.index;
              }
            }
          }
        }
        setPaths(
          textPaths.map((textPath) => ({
            path: `M${textPath.points.map((point) => `${point.x},${point.y}`).join('L')}`,
            text: textPath.text,
            hidden: textPath.hidden,
          }))
        );
      }
    };

    if (!hasRenderedInitially.current && textPaths[0]) {
      textPaths[0].points = device.handheld ? [] : DEFAULT_PATH_1;
      textPaths[0].hidden = false;
      hasRenderedInitially.current = true;

      updatePaths(1, 1, {
        force: true,
      });
    }

    if (device.handheld) {
      mobileHandler.current = {
        currentAutomationIndex: 0,
        lastPushedIndex: -1,
      };

      mobileHandler.current.start = () => {
        window.clearInterval(window.drawHeroInterval);
        window.drawHeroInterval = setInterval(() => {
          const mobilePath = MOBILE_PATH_LIST[currentTextIndex];
          const point = mobilePath[mobileHandler.current.currentAutomationIndex];

          updatePaths(point.x, point.y, {
            ignoreDelta: mobileHandler.current.currentAutomationIndex === 0,
            index: mobileHandler.current.currentAutomationIndex,
          });

          mobileHandler.current.currentAutomationIndex =
            (mobileHandler.current.currentAutomationIndex + 1) % mobilePath.length;
        }, 25);
      };

      mobileHandler.current.stop = () => {
        window.clearInterval(window.drawHeroInterval);
      };
    } else {
      const handleTouchMove = (_, pos) => {
        const [x, y] = pos;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || 0;
        updatePaths(x, y + scrollTop);
      };

      touchHandler.current = touches(document.body, {
        filtered: true,
        preventSimulated: false,
      }).on('move', handleTouchMove);
    }

    return () => {
      if (device.handheld) {
        window.clearInterval(window.drawHeroInterval);
      } else {
        touchHandler.current.disable();
      }
    };
  }, [windowWidth, windowHeight]);

  useLayoutEffect(() => {
    if (!container.current) return;

    const width = container.current.clientWidth;
    const height = container.current.clientHeight;

    setDimensions({
      width,
      height,
    });
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    if (
      (!device.handheld && !touchHandler.current) ||
      (device.handheld && !mobileHandler.current)
    ) {
      return;
    }

    if (device.handheld) {
      if (inView) {
        mobileHandler.current.start();
      } else {
        mobileHandler.current.stop();
      }
    } else if (inView) {
      touchHandler.current.enable();
    } else {
      touchHandler.current.disable();
    }
  }, [inView]);

  return (
    <section className={styles.main} ref={container}>
      <div className={styles.fg}>
        <h1 className={styles.heading}>{block.title}</h1>
        <div className={classNames('site-content-container', styles.textContent)}>
          <div className="two-col-text">
            <RichText blocks={block._rawText} />
          </div>
        </div>
      </div>

      <svg
        className={styles.svg}
        viewBox={dimensions.width ? `0 0 ${dimensions.width} ${dimensions.height}` : null}
      >
        <defs>
          {paths.map(({ path }, i) => (
            <path key={i} id={`textPath${i}`} d={path} />
          ))}
        </defs>

        {paths.map((path, i) => (
          <text
            key={i}
            id={`textPath${i}`}
            className={classNames({
              [styles.hidden]: path.hidden,
            })}
            // mobile font size
            style={device.handheld ? { fontSize: '14px' } : null}
          >
            <textPath href={`#textPath${i}`}>{path.text}</textPath>
          </text>
        ))}
      </svg>
    </section>
  );
};

export default HeroDrawText;
