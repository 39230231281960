import React from 'react';
import { graphql } from 'gatsby';

/*
Components
*/
import App from '../components/App/App';
import Components from '../components/Components';
import Nav from '../components/Nav/Nav';
import HeroDrawText from '../components/HeroDrawText/HeroDrawText';
import TextArticle from '../components/TextArticle/TextArticle';
import LastFooter from '../components/LastFooter/LastFooter';

const HowWeAct = ({ data }) => {
  const {
    animatedHero,
    contentBlocks,
    _rawLastFooter,
  } = data.sanityHowWeActPage;

  return (
    <App>
      <Nav />
      <HeroDrawText block={animatedHero} />
      {contentBlocks.map((block) => Components(block))}
      <LastFooter {..._rawLastFooter} />
    </App>
  );
};

export const query = graphql`
  {
    sanityHowWeActPage(_id: { eq: "howWeAct" }) {
      animatedHero {
        animatedTexts
        _rawText
        _key
        _type
        title
      }
      contentBlocks {
        ... on SanityTextSection {
          _key
          _type
          _rawText
          bgcolor
          heading
        }
        ... on SanityParallaxingImagesSection {
          _key
          _type
          _rawTitle
          images {
            asset {
              url
            }
          }
          textContent {
            _key
            _type
            lead
            _rawSubTitle
            _rawTextSectionFirst
            _rawTextSectionSecond
            _rawTitle
          }
        }
        ... on SanityTextArticleSection {
          _key
          _type
          lead
          _rawSubTitle
          _rawTextSectionFirst
          _rawTextSectionSecond
          _rawTitle
        }
      }
      _rawLastFooter(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default HowWeAct;
