/**
 * This file is part of the Tine Sunniva Hjem til naturen 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

const clientStrings = [
  { s: 'Windows', r: /(Windows 10.0|Windows NT 10.0)/ }, // Windows 10
  { s: 'Windows', r: /(Windows 8.1|Windows NT 6.3)/ }, // Windows 8.1
  { s: 'Windows', r: /(Windows 8|Windows NT 6.2)/ }, // Windows8
  { s: 'Windows', r: /(Windows 7|Windows NT 6.1)/ }, // Windows7
  { s: 'Windows', r: /Windows NT 6.0/ }, // Windows Vista
  { s: 'Windows', r: /Windows NT 5.2/ }, // Windows Server 2003
  { s: 'Windows', r: /(Windows NT 5.1|Windows XP)/ }, // Windows XP
  { s: 'Windows', r: /(Windows NT 5.0|Windows 2000)/ }, // Windows 2000
  { s: 'Windows', r: /Windows NT/ },
  { s: 'Windows', r: /(Win 9x 4.90|Windows ME)/ }, // Windows ME
  { s: 'Windows', r: /(Windows 98|Win98)/ }, // Windows 98
  { s: 'Windows', r: /(Windows 95|Win95|Windows_95)/ }, // Windows 95
  {
    s: 'Windows',
    r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
  }, // Windows NT 4.0
  { s: 'Windows', r: /Windows CE/ }, // Windows CE
  { s: 'Windows', r: /Windows/ },
  { s: 'Windows', r: /Win64/ },
  { s: 'Windows', r: /Win16/ }, // Windows 3.11
  { s: 'Android', r: /Android/ },
  { s: 'Open-BSD', r: /OpenBSD/ },
  { s: 'Sun-OS', r: /SunOS/ },
  { s: 'Linux', r: /(Linux|X11)/ },
  { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
  { s: 'Mac', r: /Mac OS X/ }, // Mac OS X
  { s: 'Mac', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ }, // Mac OS
  { s: 'QNX', r: /QNX/ },
  { s: 'UNIX', r: /UNIX/ },
  { s: 'BeOS', r: /BeOS/ },
  { s: 'OS2', r: /OS\/2/ },
  {
    s: 'Search-Bot',
    r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
  },
];

const getOS = (ua) => {
  if (!ua) {
    throw new Error('getOS requires a userAgent string as argument');
  }

  let i = 0;

  while (i < clientStrings.length) {
    const cs = clientStrings[i];

    if (cs.r.test(ua)) {
      return cs.s;
    }

    i += 1;
  }

  return null;
};

const getBrowser = (ua) => {
  if (!ua) {
    throw new Error('getBrowser requires a userAgent string as argument');
  }

  let browser = window.navigator.appName;

  switch (true) {
    case ua.indexOf('Opera') !== -1:
    case ua.indexOf('OPR') !== -1:
      browser = 'Opera';
      break;

    case ua.indexOf('Edge') !== -1:
      browser = 'Edge';
      break;

    case ua.indexOf('MSIE') !== -1:
    case ua.indexOf('Trident/') !== -1:
      browser = 'IE';
      break;

    case ua.indexOf('Chrome') !== -1:
      browser = 'Chrome';
      break;

    case ua.indexOf('Safari') !== -1:
      browser = 'Safari';
      break;

    case ua.indexOf('Firefox') !== -1:
      browser = 'Firefox';
      break;

    case ua.lastIndexOf(' ') + 1 < ua.lastIndexOf('/'):
      browser = ua.substring(ua.lastIndexOf(' ') + 1, ua.lastIndexOf('/'));

      if (browser.toLowerCase() === browser.toUpperCase()) {
        browser = window.navigator.appName;
      }
      break;

    default:
      // no-op
      break;
  }

  return browser;
};

const getVersion = (ua, browser) => {
  if (!ua) {
    throw new Error('getBrowser requires a userAgent string as first argument');
  }

  if (!browser) {
    throw new Error('getBrowser requires a browser string as second argument');
  }

  let version = parseFloat(window.navigator.appVersion).toString();

  let verOffset = 0;

  switch (browser) {
    case 'Opera':
      if (ua.indexOf('OPR') !== -1) {
        verOffset = ua.indexOf('OPR');
        version = ua.substring(verOffset + 4);
      } else {
        verOffset = ua.indexOf('Opera');
        version = ua.substring(verOffset + 6);

        if (ua.indexOf('Version') !== -1) {
          verOffset = ua.indexOf('Version');
          version = ua.substring(verOffset + 8);
        }
      }
      break;

    case 'Edge':
      verOffset = ua.indexOf('Edge');
      version = ua.substring(verOffset + 5);
      break;

    case 'IE':
      if (ua.indexOf('Trident/') !== -1) {
        version = ua.substring(ua.indexOf('rv:') + 3);
      } else {
        verOffset = ua.indexOf('MSIE');
        version = ua.substring(verOffset + 5);
      }
      break;

    case 'Chrome':
      verOffset = ua.indexOf('Chrome');
      version = ua.substring(verOffset + 7);
      break;

    case 'Firefox':
      verOffset = ua.indexOf('Firefox');
      version = ua.substring(verOffset + 8);
      break;

    case ua.lastIndexOf(' ') + 1 < ua.lastIndexOf('/'):
      verOffset = ua.lastIndexOf('/');
      version = ua.substring(verOffset + 1);
      break;

    case 'Safari':
      verOffset = ua.indexOf('Safari');
      version = ua.substring(verOffset + 7);

      if (ua.indexOf('Version') !== -1) {
        verOffset = ua.indexOf('Version');
        version = ua.substring(verOffset + 8);
      }
      break;

    default:
      // no-op
      break;
  }

  let ix = 0;

  switch (true) {
    case version.indexOf(';') !== -1:
      ix = version.indexOf(';');
      version = version.substring(0, ix);
      break;

    case version.indexOf(' ') !== -1:
      ix = version.indexOf(' ');
      version = version.substring(0, ix);
      break;

    case version.indexOf(')') !== -1:
      ix = version.indexOf(')');
      version = version.substring(0, ix);
      break;

    default:
      // no-op
      break;
  }

  return version;
};

const getDeviceInfo = () => {
  if (typeof window === 'undefined' || !window.navigator) return {};

  const ua = window.navigator.userAgent || window.navigator.vendor;
  const standalone = window.navigator.standalone || false;

  const browser = getBrowser(ua);
  let version = getVersion(ua, browser);

  let majorVersion = parseInt(`${version}`, 10);

  if (Number.isNaN(majorVersion)) {
    version = `${parseFloat(window.navigator.appVersion)}`;
    majorVersion = parseInt(window.navigator.appVersion, 10);
  }

  const handheld = /Mobile|mini|Fennec|Android|BlackBerry|iP(ad|od|hone)/.test(ua);

  const os = getOS(ua);

  let uiWebView = false;

  if (os === 'iOS' && ua.indexOf('Safari') === -1 && !standalone) {
    uiWebView = true;

    if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) {
      uiWebView = 'FB';
    }
  }

  if (os === 'iOS' && ua.indexOf('FxiOS') !== -1) {
    uiWebView = 'Firefox';
  }

  if (os === 'iOS' && ua.indexOf('CriOS') !== -1) {
    uiWebView = 'Chrome';
  }

  return {
    browser,
    browserVersion: version,
    browserMajorVersion: majorVersion,
    uiWebView,
    standalone,
    handheld,
    ua,
    os,
  };
};

export default {
  browser: '',
  browserVersion: '',
  browserMajorVersion: 0,
  uiWebView: undefined,
  standalone: undefined,
  handheld: false,
  os: '',
  ...getDeviceInfo(),
};
